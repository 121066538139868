import type React from "react"
import { useState, useEffect } from "react"
import { api } from "../../Api"
import DefaultLayout from "../../layout/DefaultLayout"

interface Baby {
  id: number
  name: string
  age_in_days: number
}

const BabyManager: React.FC = () => {
  const [babies, setBabies] = useState<Baby[]>([])
  const [newBaby, setNewBaby] = useState({ name: "", age_in_days: 0 })
  const [editBaby, setEditBaby] = useState<Baby | null>(null)

  const fetchBabies = async () => {
    try {
      const response = await api.get("https://api.nadymama.com/api/users/babies")
      setBabies(response.data)
    } catch (error) {
      console.error("خطأ في جلب بيانات الأطفال:", error)
    }
  }

  const addBaby = async () => {
    try {
      const response = await api.post("https://api.nadymama.com/api/users/babies", newBaby, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      setBabies([...babies, response.data])
      setNewBaby({ name: "", age_in_days: 0 })
    } catch (error) {
      console.error("خطأ في إضافة الطفل:", error)
    }
  }

  const updateBaby = async () => {
    if (!editBaby) return

    try {
      const response = await api.put(`https://api.nadymama.com/api/users/babies/${editBaby.id}`, editBaby, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      setBabies(babies.map((baby) => (baby.id === editBaby.id ? response.data : baby)))
      setEditBaby(null)
    } catch (error) {
      console.error("خطأ في تحديث بيانات الطفل:", error)
    }
  }

  const deleteBaby = async (id: number) => {
    try {
      await api.delete(`https://api.nadymama.com/api/users/babies/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      setBabies(babies.filter((baby) => baby.id !== id))
    } catch (error) {
      console.error("خطأ في حذف الطفل:", error)
    }
  }

  useEffect(() => {
    fetchBabies()
  }, []) //Fixed: Added empty dependency array to useEffect

  return (
    <DefaultLayout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-12 text-center text-blue-600">أطفالي</h1>

        <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-12">
          <h2 className="text-2xl font-semibold mb-6">إضافة طفل جديد</h2>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="baby-name">
                اسم الطفل
              </label>
              <input
                id="baby-name"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text"
                placeholder="أدخل اسم الطفل"
                value={newBaby.name}
                onChange={(e) => setNewBaby({ ...newBaby, name: e.target.value })}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="baby-age">
                عمر الطفل بالأيام
              </label>
              <input
                id="baby-age"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="number"
                placeholder="أدخل عمر الطفل بالأيام"
                value={newBaby.age_in_days}
                onChange={(e) => setNewBaby({ ...newBaby, age_in_days: Number.parseInt(e.target.value) })}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              onClick={addBaby}
            >
              إضافة طفل
            </button>
          </div>
        </div>

        {editBaby && (
          <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-12">
            <h2 className="text-2xl font-semibold mb-6">تعديل بيانات الطفل</h2>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="edit-baby-name">
                  اسم الطفل
                </label>
                <input
                  id="edit-baby-name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  placeholder="أدخل اسم الطفل"
                  value={editBaby.name}
                  onChange={(e) => setEditBaby({ ...editBaby, name: e.target.value })}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="edit-baby-age">
                  عمر الطفل بالأيام
                </label>
                <input
                  id="edit-baby-age"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  placeholder="أدخل عمر الطفل بالأيام"
                  value={editBaby.age_in_days}
                  onChange={(e) => setEditBaby({ ...editBaby, age_in_days: Number.parseInt(e.target.value) })}
                />
              </div>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                onClick={updateBaby}
              >
                تحديث البيانات
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                onClick={() => setEditBaby(null)}
              >
                إلغاء
              </button>
            </div>
          </div>
        )}

        <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          <h2 className="text-2xl font-semibold mb-6">قائمة الأطفال</h2>
          {babies.length === 0 ? (
            <p className="text-center text-gray-500 py-4">لا يوجد أطفال مسجلين حالياً</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {babies.map((baby) => (
                <li key={baby.id} className="py-6 flex justify-between items-center">
                  <div>
                    <h3 className="text-xl font-semibold">{baby.name}</h3>
                    <p className="text-gray-600">العمر: {baby.age_in_days} يوم</p>
                  </div>
                  <div className="space-x-2">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                      onClick={() => setEditBaby(baby)}
                    >
                      تعديل
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                      onClick={() => deleteBaby(baby.id)}
                    >
                      حذف
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default BabyManager

